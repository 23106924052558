/* Development stuff */

$dev-enable-grid-background: false !default;

/* Colours */
$color-white: #FFF;
$color-black: #000;
$color-dark-grey: #555555;
$dark-background: #2a2829;
$color-dark-blue: #253551;
$color-light-brown: #DFE0DA;
$student-tier-color: #336C25;
$general-tier-color: #D68725;
$input-background: #fafafa;
$input-border-color: #ccc;
$form-sent-color: #4eee94;
$form-sent-error-color: #CD3525;

/* Units and typography */
$base-font-size: 17px;
$base-font-face: ('sans-serif', 'Arial');

/* Misc variables that are used in multiple contexts */
$border-radius: 5px;
$vertical-spacing: 20px;

/* Grid settings */
$grid-column-count: 12;
$grid-colum-to-gutter-ratio-column: 4;
$grid-colum-to-gutter-ratio-gutter: 1;
