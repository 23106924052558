.section-course-descriptions {
  padding: 40px 0px;
}

.course-descriptions-container {
  display: flex;

  @include media-mobile-only {
    flex-direction: column;

    .course-description-block {
      @include grid-column(12);
      @include omega(1);
      @include push-down;
    }

  }
}

.course-description-block {
  @include grid-column(6);
  @include omega(2);
}

.course-description-block__title {
  font-size: $base-font-size + 14;
  color: $color-dark-grey;
  text-align: center;
  border-bottom: 1px solid $color-dark-grey;
  margin-left: 5%;
  margin-right: 5%;
}

.course-description-block__list {
  font-size: $base-font-size + 1;
}

.course-description-block__list-item {
  margin-bottom: 5px;
}

.section-course-info {
  padding: 40px 0px;
}

.calendar__title,
.course-info__title {
  font-size: $base-font-size + 14;
  color: $color-dark-grey;
  text-align: center;
}

.course-info__text {
  font-size: $base-font-size + 5;
  line-height: $base-font-size + 25;
  margin-bottom: 50px;

  li {
    margin-bottom: 15px;
  }
}

.section-prices {
  background-color: $color-light-brown;
  padding: 40px 0px;
}

.prices-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.prices__title {
  display: flex;
  flex-direction: column;
  font-size: $base-font-size + 18;
}

.prices__price-grid {
  display: flex;
  justify-content: space-evenly;
  width: 80%;

  .prices__price-grid-item {
    @include grid-column(4);
    @include omega(2);
  }

  @include media-mobile-only {
    flex-direction: column;
    justify-content: center;

    .prices__price-grid-item {
      @include grid-column(12);
      @include omega(1);
      margin-bottom: 50px;
    }
  }
}

.section-calendar {
  @include push-down;

  .calendar-container {
    @include grid-column(10);
    @include grid-push(1);

    @include media-mobile-and-tablet {
      @include grid-column(12);
      @include grid-push(0);
    }
  }
}