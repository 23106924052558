@import "stylesheets/variables";
@import "stylesheets/functions";
@import "stylesheets/mixins";

@import "stylesheets/base";
@import "stylesheets/layout";


@import "stylesheets/pages/Home.scss";
@import "stylesheets/pages/Cursos.scss";
@import "stylesheets/pages/Contactame.scss";
@import "stylesheets/pages/Inscripcion.scss";

@import "stylesheets/components/Header.scss";
@import "stylesheets/components/Footer.scss";
@import "stylesheets/components/HeroBanner.scss";
@import "stylesheets/components/InfoBlock.scss";
@import "stylesheets/components/PriceColumn.scss";

body {
  margin: 0;
}

.main-page-header {
  text-align: center;
  font-size: 30px;
  padding: 20px;

  @include media-desktop {
    font-size: 40px;
  }
}