.section-inscription {
  padding: 40px 0px;
}

.inscription-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include grid-column(6);
  @include grid-push(3);
  @include omega(1);

  .branded-form {
    width: 100%;
    align-items: center;

    .branded-input-container {
      width: 100%;
    }
  }

  @include media-mobile-and-tablet {
    @include grid-column(10);
    @include grid-push(1);
  }

  @include media-mobile-only {
    @include grid-column(12);
    @include grid-push(0);
  }
}